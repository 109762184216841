@media  only screen and (min-width: $screen-sm) {
    .page-results {
        .subtitle-big {
            margin-bottom: 0;
        }

        .results-container {
            margin-top: -4rem;
        }

        .results-prize {
            float: right;
            margin-left: 10%;
            width: 30%;

            &-image {
                margin-bottom: 5rem;
            }

            &-description {
                margin-bottom: 5rem;
            }
        }


        .results-info {
            float: left;
            width: 60%;

            .info-sponsor {
                -webkit-box-align: center;
                -moz-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                font-size: 4.5rem;
                -moz-justify-content: center;
                -webkit-justify-content: center;
                justify-content: center;
                margin-bottom: 7rem;

                img {
                    padding-top: 0;
                    padding-left: 3.5rem;
                }
            }

        }

        .info-sondaj {
            margin-bottom: 7rem;
        }

        .results-details {
            padding-top: 12rem;
        }

        .results-raport {
            .raport-row {
                -webkit-box-align: center;
                -moz-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;

                div {
                    width: 50%;
                    padding: 0 3.7rem;
                }

                p {
                    padding-left: 3.4rem;
                }

                img {
                    margin-bottom: 0;
                }

                &-sex {
                    p {
                        span {
                            font: {
                                size: 6.3rem;
                            }
                        }
                    }
                }

                &-school {
                    div:first-child {
                        order: 2;
                    }

                    div:last-child {
                        order: 1;
                    }
                }
            }
        }

    }
}