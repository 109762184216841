@media  only screen and (min-width: $screen-sm) {
    .signup-page {
        margin-top: 3rem;
    }

    .capcha__image {
        width: 25%;
    }

    .capcha__refresh {
        width: 25%;
    }
}