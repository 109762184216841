@media only screen and(min-width: $screen-sm) {
    .active-quiz-prize-container {
        margin-top: -2.5rem;
        width: 48%;
    }

    .monthly-quiz-container-top {
        .active-quiz-prize-container {
            width: 45%;
            margin-top: -2.5rem;
        }
    }

    .active-quiz-prize__sticker {
        right: 2rem;
        width: 50%;
    }

    .active-quiz-prize__image {
        width: 100%;
    }
}