@media only screen and (min-width: $screen-sm) {

    .account-summary {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
    }

    .account-summary__daily-question {
        margin-top: 0;
    }

    .account-summary__facebook-plugin {
        width: 48%;
    }

    #fieldset-info {
        .form-group:nth-child(4),
        .form-group:nth-child(5),
        .form-group:nth-child(6) {
            .input-date {
                width: 12rem;
            }
        }

        .form-group:nth-child(5) {
            margin-left: 0;
        }
    }
}