@media only screen and (min-width: $screen-sm) {
    .thankyou {
        &-top {
            .title {
                font-size: 12rem;
                //margin-top: -2rem;
            }
        }

        .container-box {
            padding: 5rem;
        }
    }
}