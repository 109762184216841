@media  only screen and (min-width: $screen-sm) {
    .login-page {
        margin-top: 3rem;
    }

    .login {
        margin: 5.5rem 5rem 0 0;
    }

    .login__button {
        padding: 1.5rem 2.3rem;
        margin-right: 2rem;
    }

    .login__password-recovery {
        text-align: left;
    }

    .login__facebook-login {
        padding: 1.5rem 2.3rem;
    }
}