@media only screen and (min-width: $screen-sm) {

    .points-page {
        .points__subtitle {
            margin: 1rem 0 5rem;
        }

        .disclaimer__description {
            margin: 0;
        }

        .extra-chance__title {
            margin-top: 8rem;
        }

        .extra-chance__subtitle {
            margin: 0;
        }

        .extra-chance__button {
            font-size: 1.3rem;
            letter-spacing: 0.1rem;
            padding: 1.8rem 2.3rem;
        }

        .vouchers__title {
            margin-top: 8rem;
        }

        .voucher-container {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex: {
                direction: row;
                wrap: wrap;
            }
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            justify-content: space-between;
            width: 100%;

            .voucher {
                width: 48%;
            }
        }

        .vouchers__footer {
            margin: 3rem 0;
        }
    }


    /*----------------------------------
        #Detalii Cupon
    ----------------------------------*/
    .account-container {
        .coupon-info-container {
            padding-right: 0;
        }
    }

    .coupon-info-container {
        .detalii-cupon {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex: {
                direction: row;
               // wrap: wrap;
            }
            -webkit-justify-content: space-between;
            justify-content: space-between;
            width: 100%;
            align-items: flex-start;

            .voucher {
                order: 2;
                margin-top: -4rem;
                padding: 5rem 4rem;
                width: 34%;
            }

            .voucher__spacer {
                top: 14rem;
            }

            .voucher__discount {
                margin: 8rem 0 4rem 0;
            }

            .descriere-cupon {
                margin-top: 0;
                width: 58%;
                order: 1;
            }
        }


        //old template
        .DetaliiCupon {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex: {
                direction: row;
                // wrap: wrap;
            }
            -webkit-justify-content: space-between;
            justify-content: space-between;
            width: 100%;
            align-items: flex-start;

            > div:first-child {
                background-position: center 19rem !important;
                margin-top: -4rem;
                order: 2;
                padding: 5rem 4rem;
                width: 34%;

                &:before,
                &:after {
                    top: 14rem;
                }

                .button {
                    margin-top: 18rem;
                }

            }

            .DescriereCupon {
                margin-top: 0;
                width: 58%;
                order: 1;
            }
        }


    }
}