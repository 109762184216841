@media only screen and (min-width: $screen-sm) {
    .monthly-quiz-title {
        font-size: 13.1rem;
    }

    .daily-question-message,
    .monthly-quiz-message {
        font-size: 2.7rem;
        margin-left: -2rem;
    }

    .daily-question-message {
        margin-left: 0;
    }

    .monthly-quiz-container-top {
        flex-direction: row;
        -webkit-flex-direction: row;

        .fb-like {
          display: block;
          margin: 0;
        }
    }

    .monthly-quiz-description {
        font-size: 1.7rem;
    }

    .monthly-quiz {
        width: 50%;
    }

    .monthly-quiz-container-bottom {
        align-items: center;
        flex-direction: row;
    }

    .client-information {
        margin-top: -1rem;
        width: 65%;
    }

    .quiz-sponsor {
        font-size: 6rem;
    }

    .client-information-title {
        margin: -1.5rem 3rem 3rem;
        width: 120%;
    }

    .client-information-description {
        margin: 0 2rem 0 3rem;
    }

    .client-information-aditional-info {
        margin: 2rem 2rem 0 3rem;
    }

    .client-image {
        margin-top: 6rem;
        width: 35%;

        img {
            width: 100%;
        }
    }
}