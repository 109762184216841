@media only screen and(min-width: $screen-sm) {
    /* --- Anti-Spam --- */
    .anti-spam {
        &__title {
            padding: 0;
        }

        &__description {
            padding: 4rem 0 4rem 0;
        }
    }
}