.ivox-info__description {
  padding: 0 5rem;
}


.ivox-info__subtitle {
  padding: 5rem 5rem 2rem;
}


.ivox-info__description--grey {
  padding: 1.5rem 5rem;

  &:last-child {
    padding-bottom: 5rem;
  }
}