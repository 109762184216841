@media only screen and (min-width: $screen-sm) {
    .active-quiz {
        margin-top: -6rem;
        width: 50%;

        &__question {
            margin-top: 0;
        }

        &-container {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            -webkit-flex-direction: row;
        }

        &__footer {
            font-size: 2.7rem;
        }
    }

    .daily-question {
        &__title {
            font-size: 15rem;
            letter-spacing: -.25rem;
            margin: -1.5rem 0 0 0;
        }

        &__subtitle {
            font-size: 6rem;
            margin: -3rem 0 0 0;
        }

        &__content {
            font-size: 2.3rem;
            padding: 3rem 0;
        }
    }

    .homepage-container-bottom {
        flex-direction: row;
        -webkit-flex-direction: row;
        flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        margin-top: 8rem;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
    }

    .past-quiz {
        width: 49%;

        &__title {
            margin-bottom: 0.2rem;
        }
    }

    .business {
        margin: 5rem 0 0 0;
        padding: 0 3rem;

        &__title {
            margin-top: 2rem;
        }

        &__description {
            clear: both;
            margin-top: 3rem;
            margin-bottom: 4.5rem;
            width: 100%;
        }

        &__more-info-link {
            bottom: 0;
        }
    }
}
