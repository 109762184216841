/*----------------------------------
    #Mountly Quiz - Steps
----------------------------------*/

@media only screen and (min-width: $screen-sm) {
    .progress-3 {
        right: 11.8rem;
    }

    .progress-number {
        font: {
            size: 4.3rem;
        }
        padding-right: 2.5rem;
    }

    .quiz-steps {
        flex-direction: row;
        justify-content: space-between;
    }

    .quiz-step {
        margin: 0;
        width: 24%;
    }

    .quiz-step__title {
        font-size: 3.9rem;
    }

    .Table.default {
        .question-head {
            display: block;
        }

        .question-line {
            display: flex;
            flex-direction: row;
            -webkit-flex-direction: row;
        }

        .question-head-col,
        .question-col {
            flex-basis: 0;
            flex-grow: 1;
        }

        .question-head-col {
            padding-bottom: 2rem;
            line-height: 1.8rem;
            padding-right: 2rem;
        }

        .question-head-label {
            display: none;
        }

        .question-row {
            clear: both;
            width: 100%;
        }

        &.Horizontal {
            .question-line {
                .form-group {
                    .question-label {
                        display: none;
                    }
                }
            }
        }
    }


    /*----------------------------------
        Tabel - Questions
    ----------------------------------*/
    .Table.apply-mobile-view {
        clear: both;
        text-align: left;
        width: 100%;

        .question-head {
            display: none;
        }

        .question-row {
            margin-bottom: 1rem;
        }

        .question-head-col,
        .question-head-label {
            font-size: 1.3rem;
            line-height: 2.3rem;
            opacity: 0.4;
            text-transform: capitalize;
        }

        &.Horizontal {
            .question-head-col,
            .question-head-label {
                opacity: 1;
            }
        }
    }

}