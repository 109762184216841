@media only screen and(min-width: $screen-sm) {
    .press-articles-container {
        flex-direction: row;
        -webkit-flex-direction: row;
        flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        justify-content: space-between;
        -webkit-justify-content: space-between;
    }

    .press-article {
        width: 48%;
    }
}