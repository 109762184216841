@media only screen and (min-width: $screen-sm) {
    .container-box {
        padding: 5rem;
    }

  /* --- Text --- */

    .title__mask {
        .title {
            font-size: 50rem;
            line-height: 40rem;
        }
    }

    .title__container {
        text-align: left;
        width: 90%;
    }

    .title {
        font-size: 15.5rem;
        white-space: nowrap;
        letter-spacing: -1rem;
    }

    .subtitle {
        font-size: 2.3rem;
    }

    .ivox-info__description-list {
        margin-left: 6.5rem !important;
    }

    .menu-tabs {
        font: {
            size: 1.6rem;
        }

        li {
            padding: 0.8rem 0 1rem 0;
            margin-right: 2.2rem;

            &.Active {
                padding: 0.8rem 2.2rem 1rem 2.2rem;
            }
        }
    }
}