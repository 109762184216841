@media only screen and (min-width: $screen-sm) {
    .daily-question {
        &-page__question {
            margin: -4rem 0 2.8rem 0;
        }
    }

    .fb-like {
        margin-left: 0;
    }

    .daily-question__monthly-quiz-redirect {
        margin: 5rem 0 5rem 1rem;
    }

    .results__description {
        margin: 2rem 0;
    }

    .charts-container {
        flex-direction: row;
        margin: 0 5rem;
        width: auto;
    }

    .chart {
        margin: 5rem 0;
        width: 35%;
        margin-right: 5%;

        &:last-child {
            margin-right: 0;
        }
    }

    .daily-question-archive {
        .sponsor {
            width: 35%;
        }

        .daily {
            &.daily-sponsor {
                max-width: 65%;
                padding: 0 3rem;
            }
        }
    }

    .daily-statistic {
        .daily {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            -webkit-flex-direction: row;
        }

        .question {
            order: 1;
        }

        .sponsor {
            order: 2;
            margin-left: 3rem;
            text-align: center;

            img {
                margin: 0 auto;
            }

            p {
                text-align: left;
            }
        }
    }
}