@media only screen and (min-width: $screen-sm) {
    .istoric-navigation {
        font-size: 2.3rem;
        //margin-left: 3rem;
        padding: 0;
    }

    .past-quiz__month {
        //padding: 3rem 0 0;
    }

    .past-quizzes-container {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -webkit-justify-content: space-between;
        justify-content: space-between;

    }

    .past-quiz-container {
        margin-top: 2.5rem;
        position: relative;
        width: 48%;
    }

    .past-questions-container {
        flex-direction: row;
        -webkit-flex-direction: row;
        flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }

    .past-question-container {
        width: 48%;
    }
}